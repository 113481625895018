import {defineStore} from "pinia";

import {ref} from "vue";
type DataState = {
    prices: {
        records: any
    } | any,
    blocks: {
        records: any
    } | any,
    contacts: {
        records: any
    } | any,
    loading: Boolean,
    error: string | any
};
const defaultState: DataState = {
    prices: {
        records: []
    },
    blocks: {
        records: []
    },
    contacts: {
        records: []
    },
    loading: false,
    error: ''
}
export const useDataStore = (id = "data") =>
    defineStore(id, () => {
        // state
        const state = ref<DataState>(JSON.parse(JSON.stringify(defaultState)));


        const fetchData = async () => {
            state.value.loading = true;
            await Promise.all([
                $fetch('/api/prices'),
                $fetch('/api/blocks'),
                $fetch('/api/contacts'),
            ]).then(([prices, blocks, contacts]) => {
                if (prices) {
                    state.value.prices = prices;
                }
                if (blocks) {
                    state.value.blocks = blocks;
                }
                if (contacts) {
                    state.value.contacts = contacts;
                }
            })
            state.value.loading = false;
        };

        return {
            state,
            fetchData
        };
    })();



